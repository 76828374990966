/* Common CSS Start */

@media (min-width: 992px) {
  html {
    font-size: 60%;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 68%;
  }
}
@media (min-width: 1300px) {
  html {
    font-size: 70%;
  }
}
@media (min-width: 1400px) {
  html {
    font-size: 75%;
  }
}
@media (min-width: 1500px) {
  html {
    font-size: 80%;
  }
}
@media (min-width: 1700px) {
  html {
    font-size: 91%;
  }
}
@media (min-width: 1800px) {
  html {
    font-size: 100%;
  }
}

body {
  transition: all 0.3s ease-in-out;
}
.bg_F6F6F6 {
  background-color: #f6f6f6;
}
.section-heading {
  font-size: 2.875rem;
}
.py-100 {
  padding: 6.25rem 0;
}
.homeBtn {
  min-width: 9.563rem;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.homeBtn svg {
  width: 1rem;
  height: 1rem;
  margin-left: 0.875rem;
  transition: transform 0.3s ease-out;
  -webkit-transition: transform 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out;
  -ms-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
}
.homeBtn:hover svg {
  transform: translateX(0.625rem);
  -webkit-transform: translateX(0.625rem);
  -moz-transform: translateX(0.625rem);
  -ms-transform: translateX(0.625rem);
  -o-transform: translateX(0.625rem);
}
@media (max-width: 767.98px) {
  .py-100 {
    padding: 3.25rem 0;
  }
}
@media (max-width: 575.98px) {
  
  .section-heading {
    font-size: 1.875rem;
  }
  .section-heading + p.fs-5 + p.fs-5,
  .section-heading + p.fs-5 {
    font-size: 1rem !important;
  }
  
}
/* Common CSS End */

/* Header CSS Start*/
.header-nav {
  padding: 1.25rem 0;
}
.header-nav.header-fixed-top::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: rgba(255, 228, 214, 0.5);
  box-shadow: 0 4px 30px rgba(255, 228, 214, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.header-logo {
  width: 15.938rem;
  height: 6.25rem;
  --bs-btn-padding-x: 1.5rem;
}
.contact-btn {
  width: 9.5rem;
  max-width: 100%;
  --bs-btn-padding-x: 1.5rem;
}
.menu-btn {
  background-color: #e0e0f5;
  width: 3.5rem;
  height: 3.5rem;
  z-index: 1035;
  left: 5rem;
  top: 2.625rem;
}
.menu-btn span {
  display: block;
  height: 0.125rem;
  width: 1.5rem;
  background: #27276f;
  transition: all 0.15s ease;
  position: absolute;
  left: 1rem;
  transform: rotate(0deg);
}

.menu-btn span:first-child {
  width: 1.5rem;
  top: 1.341rem;
}
.menu-btn span:last-child {
  width: 1.125rem;
  top: 2.011rem;
}

.menu-btn.menubtn-on span {
  top: 1.688rem;
}
.menu-btn.menubtn-on span:first-child {
  transform: rotate(45deg);
}
.menu-btn.menubtn-on span:last-child {
  transform: rotate(-45deg);
  width: 1.5rem;
}

@media (max-width: 991.98px) {
  .header-nav {
    padding: 1rem 0;
  }
  .header-logo {
    width: 7.938rem;
    height: 3.25rem;
  }
  .contact-btn {
    width: auto;
    --bs-btn-padding-y: 0.5rem;
  }
  .menu-btn {
    left: 2rem;
    top: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .contact-btn {
    --bs-btn-font-size: 0.75rem;
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
  }
  .menu-btn {
    width: 2.5rem;
    height: 2.5rem;
  }
  .menu-btn span {
    left: 0.714rem;
  }
  .menu-btn span:first-child {
    width: 1.071rem;
    top: 0.958rem;
  }
  .menu-btn span:last-child {
    width: 0.804rem;
    top: 1.436rem;
  }

  .menu-btn.menubtn-on span {
    top: 1.188rem;
  }
  .menu-btn.menubtn-on span:last-child {
    width: 1.071rem;
  }
  .menu-btn {
    left: 2.5rem;
    top: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .menu-btn {
    left: 0.875rem;
    top: 1.45rem;
  }
}

/* Menu CSS  */
.page-menu {
  background-color: rgb(39, 39, 111);
  z-index: 1032;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.page-menu.menu-open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
.menu-link {
  font-size: 2.875rem;
  text-decoration: none;
  color: #ffffff;
}
.menu-list-ul li {
  margin-bottom: 2.5rem;
}
.menu-list-ul li:last-child {
  margin-bottom: 0;
}
.contact-lable {
  font-size: 2.25rem;
}
.contact__us {
  border-left: 1px solid #ffffff;
  padding-left: 5rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.header-c-link svg {
  width: 2.438rem;
  height: 2.438rem;
  flex: 0 0 2.438rem;
  margin-right: 0.75rem;
  margin-top: -0.313rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
@media (max-width: 991.98px) {
  .menu-list-ul li {
    margin-bottom: 1.5rem;
  }
  .menu-link {
    font-size: 1.875rem;
  }
  .contact-lable {
    font-size: 1.25rem;
  }
  .contact__us {
    padding-left: 2rem;
  }
}
@media (max-width: 767.98px) {
  .contact__us {
    border-left: 0;
    border-top: 1px solid #ffffff;
    padding-left: 0;
    padding-top: 1rem;
    margin-top: 1rem;
  }
  .page-menu {
    padding-top: 6rem;
  }
}
@media (max-width: 575.98px) {
  .page-menu {
    padding-top: 6rem;
  }
  .menu-link {
    font-size: 1.25rem;
  }
}
/* Header CSS End */

/* Hero Section Start */
.hero-section {
  padding-top: 13.125rem;
  padding-bottom: 10.625rem;

  background-image: url("../images/hero-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-section-text h1 {
  font-size: 2.063rem;
  margin-bottom: 0.875rem;
}
.hero-section-text p {
  margin-bottom: 1.25rem;
}
.hero-image {
  width: 56.563rem;
  right: 0.75rem;
  z-index: 100;
}
.hero-image::before {
  content: "";
  display: block;
  padding-top: 105.52%;
}
.trustpilotLogo {
  width: 14.938rem;
  height: 7.063rem;
}
@media (min-width: 576px) {
  .hero-section-text h1 {
    font-size: 3.063rem;
  }
}
@media (min-width: 992px) {
  .hero-section {
    height: 53.25rem;
    background-size: 100% 100%;
  }
  .hero-section-text {
    padding-top: 4.25rem;
  }
}
@media (min-width: 1200px) {
  .hero-section-text h1 {
    font-size: 4.063rem;
    margin-bottom: 1.875rem;
  }
}

@media (max-width: 991.98px) {
  .hero-image.position-absolute {
    position: relative !important;
    right: 0;
    margin-bottom: -24rem;
  }
}
@media (max-width: 575.98px) {
  .hero-section {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .hero-section-text p {
    font-size: 1rem !important;
  }
}
/* Hero Section End */

/* Accredited experts Section Start */
.ae-section {
  background-image: url("../images/black-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 18rem 0 10rem;
}
.ae-section::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.95);
}

.ae-image {
  width: 45.125rem;
  height: 0;
  padding-top: 61.916%;
  border-radius: 12.5rem 0 0px 0px;
  -webkit-border-radius: 12.5rem 0 0px 0px;
  -moz-border-radius: 12.5rem 0 0px 0px;
  -ms-border-radius: 12.5rem 0 0px 0px;
  -o-border-radius: 12.5rem 0 0px 0px;
}
.ae-sm-img {
  width: 18.625rem;
  height: 18.625rem;
  bottom: -5.5rem !important;
  border: 1.25rem solid #1a1a1a;
  border-radius: 1.25rem;
  -webkit-border-radius: 1.25rem;
  -moz-border-radius: 1.25rem;
  -ms-border-radius: 1.25rem;
  -o-border-radius: 1.25rem;
}
@media (min-width: 1200px) {
  .ae-section {
    padding: 24rem 0 10rem;
  }
  .ae-sm-img {
    width: 20.625rem;
    height: 20.625rem;
  }
}
@media (max-width: 991.98px) {
  .ae_column {
    margin-bottom: 5rem;
  }
  .ae-section {
    padding: 18rem 0 5rem;
  }
}
@media (max-width: 767.98px) {
  .ae-sm-img {
    width: 13.625rem;
    height: 13.625rem;
    border-width: 1rem;
  }
}
@media (max-width: 575.98px) {
  .ae-sm-img {
    width: 9.625rem;
    height: 9.625rem;
    border-width: 0.75rem;
  }
  .ae-image {
    border-radius: 8rem 0 0px 0px;
    -webkit-border-radius: 8rem 0 0px 0px;
    -moz-border-radius: 8rem 0 0px 0px;
    -ms-border-radius: 8rem 0 0px 0px;
    -o-border-radius: 8rem 0 0px 0px;
  }
}
/* Accredited experts Section End */

/* Our Services Section Start */

.service-card {
  background-color: #f6f6f6;
  color: #1a1a1a;
  width: 26.563rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.service-page-service .service-card {
  background-color: #ffffff;
}
.service-card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/service-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.service-card::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: rgba(39, 39, 111, 0.9);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.service-card .card-body {
  padding: 2.5rem 1.5rem;
}
.service-card .card-body svg {
  width: 7.375rem;
  height: 7.375rem;
  margin-bottom: 1.25rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.service-card:hover {
  color: #ffffff;
}
.service-card:hover::before {
  opacity: 1;
}
.service-card:hover::after {
  width: 100%;
  height: 100%;
}
@media (min-width: 1200px) {
  .service-card .card-body {
    padding: 2.5rem;
  }
}
@media (max-width: 575.98px) {
  .service-card .fs-5 {
    font-size: 1rem !important;
  }
  .service-card .card-body svg {
    width: 4.375rem;
    height: 4.375rem;
  }
}
/* Our Services Section End */

/* Testimonials Section Start */

.tm_image::before {
  content: "";
  display: block;
  padding-top: 91.67%; /* This is the aspect ratio (884 / 960) expressed as a percentage */
}
.tm-section {
  padding: 3.188rem 3.75rem;
}
.tm_card {
  width: 42rem;
  margin-bottom: 2.5rem;
}
.tm_card .card-body {
  padding: 3.125rem;
}
.mb-70 {
  margin-bottom: 4.375rem;
}
.tm-w-img {
  width: 3.75rem;
  height: 3.75rem;
}
.comaSvg {
  width: 5.063rem;
  height: 3.75rem;
}

.npArrow svg{
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
@media (min-width: 992px) {
  .tm-section {
    padding: 7.188rem 3.75rem;
  }
}
@media (max-width: 575.98px) {
  .tm-section {
    padding: 2rem 1rem;
  }
  .tm_card{
    margin-bottom: 1.5rem;
  }
  .tm_card .card-body {
    padding: 1.5rem 1rem;
  }
  .tm_card .card-body .fs-5 {
    font-size: 1rem !important;
  }
  .mb-70 {
    margin-bottom: 2rem;
  }
  .comaSvg {
    width: 3.063rem;
    height: 1.75rem;
  }
  .tm-w-img {
    width: 2.75rem;
    height: 2.75rem;
  }
  .tm_card .fs-4 {
    font-size: 1.15rem !important;
  }
  .tm_card .fs-6 {
    font-size: 0.875rem !important;
  }
}
/* Testimonials Section End */

/* FAQ Section Start */
.ant-collapse {
  font-family: 'GT Walsheim Trial Rg';
  font-style: normal;
}
.faqRow .ant-collapse-header {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  padding-right: 4rem !important;
}
.faqRow .ant-collapse-content{
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  border-top: 0;
  border-radius: 0 0 0.625rem 0.625rem;
  -webkit-border-radius: 0 0 0.625rem 0.625rem;
  -moz-border-radius: 0 0 0.625rem 0.625rem;
  -ms-border-radius: 0 0 0.625rem 0.625rem;
  -o-border-radius: 0 0 0.625rem 0.625rem;
}
.ant-collapse-expand-icon{
  position: absolute;
  right: 1.875rem;
  top: 1.25rem;
  padding: 0 !important;
}

.faqRow .ant-collapse-arrow.anticon svg{
  width: 1.375rem;
  height: 1.375rem;
  fill: #FF7E34;
}
.faqRow .ant-collapse-header-text{
  font-weight: 700;
  font-size: 1.5rem;
  font-family: 'GT Walsheim Trial Bd';
  font-weight: bold;
}
.faqRow .ant-collapse-header[aria-expanded="true"]{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0px -14px 20px 0px rgba(0, 0, 0, 0.1);
}
.faqRow .ant-collapse-content .ant-collapse-content-box{
  padding-top: 0;
  border-radius: 0 0 0.625rem 0.625rem;
  -webkit-border-radius: 0 0 0.625rem 0.625rem;
  -moz-border-radius: 0 0 0.625rem 0.625rem;
  -ms-border-radius: 0 0 0.625rem 0.625rem;
  -o-border-radius: 0 0 0.625rem 0.625rem;
}

@media (max-width: 575.98px) {
  .faqRow .ant-collapse-header-text{
    font-size: 1rem;
  }
  .faqRow .fs-5{
    font-size: 0.875rem !important;
  }
  .ant-collapse-expand-icon{
    right: 0.875rem;
    top: 1rem;
  }
  .faqRow .ant-collapse-arrow.anticon svg{
    width: 1rem;
    height: 1rem;
  }
  .faqRow .ant-collapse-header{
    padding-right: 2.25rem !important;
  }
}
/* FAQ Section End */

/* Contact Us Start */
.contact-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);
}
.contactForm {
  width: 72.875rem;
}

.contactForm .form-control {
  border-color: transparent;
  background: rgba(61, 61, 61, 0.5);
  border-radius: 4px;
  box-shadow: none !important;
  color: #ffffff;
}
.contactForm .form-control:focus{
  border-color: #ffffff;
}
.contactForm .form-control-lg {
  min-height: calc(2rem + 1rem + calc(var(--bs-border-width)* 4));
}
.contactSecTime{
  margin-bottom: -13rem;
  margin-top: 1rem;
}

.contactForm .form-control::-moz-placeholder{
  color: #FFFFFF;
  font-size: 1rem;
}
.contactForm .form-control::placeholder{
  color: #FFFFFF;
  font-size: 1rem;
}
/* Contact Us End */

/* Sub Pages CSS Start */
.subpage-hero-section {
  background-image: url(../images/hero-img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 16rem 0;
}
.fs-65 {
  font-size: 4.063rem;
}
.subpage-hero-section::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.breadcrumb {
  font-size: 1.5rem;
}
.breadcrumb-item a,
.breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-item a {
  text-decoration: none;
}
@media (min-width: 992px) {
  .subpage-hero-section .container{
    max-width: 85.25rem;
  }
}
@media (min-width: 1200px) {
  .subpage-hero-section .container{
    max-width: 100.25rem;
  }
}

@media (max-width: 991.98px) {
  .subpage-hero-section {
    padding: 9rem 0 7rem;
  }
  .fs-65 {
    font-size: 2.5rem;
  }
  .breadcrumb {
    font-size: 1.25rem;
  }
  .contactSecTime{
    margin-bottom: -5rem;
  }
}
@media (max-width: 767.98px) {
  .contactSecTime .fs-4{
    font-size: 1rem !important;
    margin-bottom: 0.5rem;
  }
}
/* About page CSS Start */
.fs-88 {
  font-size: 5.5rem;
}
.fs-28 {
  font-size: 1.75rem;
}
.discover-section {
  background-image: url(../images/discover-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.discover-section::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.discover-section .btn-primary svg[width="19"][height="19"]{
  vertical-align: -0.25rem;
  margin-right: 0.5rem;
}

.oceImg {
  height: 6.875rem;
}
.imgSliderBox{
  width: 60rem;
}
.imgSliderBox::before{
  content: '';
  display: block;
  padding-bottom: 64.58%;
}

.aboutImageSlider .prev-arrow{
  left: 4rem;
}
.aboutImageSlider .next-arrow{
  right: 4rem;
}
@media (max-width: 991.98px) {
  .fs-88 {
    font-size: 2.5rem;
  }
  .fs-28 {
    font-size: 1.25rem;
  }
  .about-section-count .fs-5 {
    font-size: 1rem !important;
  }
}
@media (max-width: 767.98px) {
  .npArrow svg{
    width: 1.75rem;
    height: 1.75rem;
  }
  .aboutImageSlider .prev-arrow{
    left: 2.5rem;
  }
  .aboutImageSlider .next-arrow{
    right: 2.5rem;
  }
  .oceImg{
    height: 2.875rem;
  }
}
/* About page CSS End */

/* Service Image  Start*/
.searviceImg {
  width: 53.438rem;
  border-radius: 0 12.5rem 0px 0px;
  -webkit-border-radius: 0 12.5rem 0px 0px;
  -moz-border-radius: 0 12.5rem 0px 0px;
  -ms-border-radius: 0 12.5rem 0px 0px;
  -o-border-radius: 0 12.5rem 0px 0px;
}
.searviceImg::before {
  content: "";
  display: block;
  padding-top: 72.51%;
}
.dotSqare {
  bottom: -2.5rem;
  right: -3.75rem;
  z-index: -1;
}
@media (max-width: 991.98px) {
  .dotSqare {
    right: 0;
  }
  .searviceImg {
    border-radius: 0 7.5rem 0px 0px;
    -webkit-border-radius: 0 7.5rem 0px 0px;
    -moz-border-radius: 0 7.5rem 0px 0px;
    -ms-border-radius: 0 7.5rem 0px 0px;
    -o-border-radius: 0 7.5rem 0px 0px;
}
}

/* Service Image  End*/

/* Contact Page Start */
.con-section {
  padding: 6.875rem 3.75rem;
}
.contact_card .card-body {
  padding: 2.5rem;
}
.contact_card .card-body svg {
  width: 5.063rem;
  height: 5.063rem;
  margin-bottom: 1.5rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

@media (max-width: 991.98px) {
  .contact_card .card-body {
    padding: 2.5rem 1rem;
  }
  .con-section {
    padding: 3.875rem 3.75rem;
  }
  .contact_card .fs-5{
    font-size: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .contact_card .card-body {
    padding: 2.5rem 1rem;
  }
  .con-section {
    padding: 2rem 1.5rem;
}

}
/* Contact Page End */

/* Sub Pages CSS End */

/* Footer Start */
.footer-section {
  background-color: #27276f;
  padding: 3.75rem 0;
}
.footer-lable {
  font-size: 2.25rem;
}

.footer-link svg {
  width: 2.438rem;
  height: 2.438rem;
  flex: 0 0 2.438rem;
  margin-right: 0.75rem;
  margin-top: -0.313rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.footer-bottom {
  background-color: #1a1a62;
}
.emailForm button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.25rem;
}
.emailForm button svg{
  width: 1rem;
  height: 1rem;
  margin-left: 0.75rem;
  vertical-align: -0.188rem;
  transition: transform 0.3s ease-out;
  -webkit-transition: transform 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out;
  -ms-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
}
.emailForm button:hover svg{
  transform: translateX(0.625rem);
  -webkit-transform: translateX(0.625rem);
  -moz-transform: translateX(0.625rem);
  -ms-transform: translateX(0.625rem);
  -o-transform: translateX(0.625rem);
}
.emailForm input.form-control {
  padding-right: 9rem;
}
.emailForm .form-control-lg {
  min-height: calc(2rem + 1.313rem + calc(var(--bs-border-width) * 2));
  font-size: 1rem;
}
@media (max-width: 991.98px) {
  .footer-lable {
    font-size: 1.25rem;
  }
  .footer-section .fs-5 {
    font-size: 1rem !important;
  }
  .footer-bottom .fs-5 {
    font-size: 1rem !important;
  }
}
@media (max-width: 767.98px) {
  .footer-bottom .fs-5 {
    font-size: 0.875rem !important;
  }
  .footer-section {
    padding: 1.75rem 0;
  }
}
/* Footer End */

