@font-face {
    font-family: 'GT Walsheim Trial Bd';
    src: url('GTWalsheimTrial-Bd.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Trial Md';
    src: url('GTWalsheimTrial-Md.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Trial Bl';
    src: url('GTWalsheimTrial-Bl.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Trial Rg';
    src: url('GTWalsheimTrial-Rg.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

